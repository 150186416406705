<template>
  <b-overlay
    :show="$apollo.loading || mutationLoading"
    rounded="sm"
    spinner-variant="primary"
  >
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        class="form-inline"
        label="Search"
        label-size="sm"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search feedbacks"
          type="text"
        />
        <b-button
          variant="primary"
          @click="openFeedbackModal"
        >
          <feather-icon icon="PlusIcon" />
          Add Feedback
        </b-button>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="mentorColumns"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.mentorsColumn.field === 'actions'"
          class="d-flex flex-nowrap"
        >
          <b-button
            size="sm"
            variant="outline-primary"
            class="mr-50"
            :to="{ name: 'forms-details-preview', params: { fid: props.row.id, mname: props.row.title } }"
          ><feather-icon icon="EyeIcon" /></b-button>
          <b-button
            v-b-modal.CopyModal
            size="sm"
            variant="outline-primary"
            class="mr-50"
          >
            <feather-icon icon="CopyIcon" />
          </b-button>
          <b-button
            size="sm"
            variant="outline-danger"
            @click="deleteModule()"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </span>
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.mentorColumns.field] }}
        </span>
      </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :per-page="pageLength"
              :total-rows="props.total"
              :value="1"
              align="right"
              class="mt-1 mb-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal
      id="feedbackModal"
      ok-title="Use format"
      no-close-on-backdrop
      size="xl"
      title="Select Feedback Format"
      @ok="handleFeedbackModalOkClick"
    >
      <h5><strong>Formats</strong></h5>
      <b-row>
        <b-col
          md="4"
          style="border-right: solid 1px #ebe9f1"
          class="pr-0"
        >
          <div
            v-for="(format, i) in feedbacks"
            :key="i"
          >
            <h6
              :class="`d-flex align-items-center mb-0 py-75 ${format.open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
              style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1; border-left: solid 1px #ebe9f1"
              @click="advanceSelector(i)"
            >
              <div class="d-flex align-items-center">
                <span>{{ format.title }}</span>
              </div>
              <feather-icon
                icon="ChevronRightIcon"
                class="ml-50"
              />
            </h6>
          </div>
        </b-col>
        <b-col md="8">
          <div
            v-for="(format, i) in feedbacks"
            :key="i"
            class="d-flex align-items-center justify-content-center "
          >
            <h1 v-if="format.selected">
              {{ format.content.title }}
            </h1>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="feedbackForwardModal"
      ok-title="Submit"
      no-close-on-backdrop
      size="lg"
      title="Select Feedback Format"
    >
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Select Startups"
            label-for="selectStartups"
          >
            <v-select
              id="selectStartups"
              v-model="startups"
              :get-option-label="option => option.title"
              :options="startups"
              :reduce="option => option.id"
              multiple
              placeholder="Select Startups"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Select Incubators"
            label-for="selectIncubators"
          >
            <v-select
              id="selectIncubators"
              v-model="incubators"
              :get-option-label="option => option.full_name"
              :options="partners"
              :reduce="option => option.id"
              multiple
              placeholder="Select Incubators"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Title"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="title"
              placeholder="Enter title"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="description"
              placeholder="Enter Description"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="CopyModal"
      title="Copy Link"
      ok-title="Copy"
    >
      <b-card-title>Feedback title</b-card-title>
      <b-input-group>
        <b-form-input
          :id="`application-link`"
          size="sm"
          :value="`hello world`"
          @keydown="event => event.preventDefault()"
          @focus="event => {event.target.select()}"
        />
        <template #append>
          <!-- Copy to Clipboard button with Copy icon at start -->
          <b-button
            size="sm"
            variant="outline-primary"
            @click="copyToClipboard('hello world')"
          >
            <feather-icon icon="CopyIcon" />
            Copy
          </b-button>
        </template>
      </b-input-group>
    </b-modal>
  </b-overlay>
</template>
<script>

import {
  // BBadge,
  BButton,
  BCol,
  // BFormCheckbox,
  BFormTextarea,
  BInputGroup,
  // BTab,
  BFormGroup,
  BFormInput,
  // BTabs,
  BFormSelect,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
// import vSelect from 'vue-select'
// import FormBuilder from '@/views/incubator-portal/programs/components/FormBuilder2.vue'
// import Treeselect from '@riophae/vue-treeselect'
import vSelect from 'vue-select'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BBadge,
    BRow,
    BCol,
    BFormTextarea,
    BFormInput,
    // BTabs,
    BInputGroup,
    // BTab,
    BFormGroup,
    // BFormCheckbox,
    vSelect,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    // FormBuilder,
    // vSelect,
    // Treeselect,
  },
  data() {
    return {
      mutationLoading: false,
      searchTerm: '',
      applicationForm: null,
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      feedbacks: [
        {
          open: false,
          selected: false,
          content: {
            title: 'format1',
            description: 'desc1',
          },
          title: 'Format 1',
        },
        {
          open: false,
          selected: false,
          content: {
            title: 'format2',
            description: 'desc2',
          },
          title: 'Format 2',
        },
        {
          open: false,
          selected: false,
          content: {
            title: 'format3',
            description: 'desc3',
          },
          title: 'Format 3',
        },
        {
          open: false,
          selected: false,
          content: {
            title: 'format4',
            description: 'desc4',
          },
          title: 'Format 4',
        },
        {
          open: false,
          selected: false,
          content: {
            title: 'format5',
            description: 'desc5',
          },
          title: 'Format 5',
        },
      ],
      mentorColumns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Format Type',
          field: 'format_type',
        },
        {
          label: 'Incubator Name',
          field: 'startup_mentor_name',
        },
        {
          label: 'Date Created',
          field: 'date_created',
        },
        {
          label: 'Actions',
          field: 'actions',
        },
      ],
      operations: [],
      reviewTitle: null,
      selectedOperation: null,
      reviewType: null,
    }
  },
  computed: {
    rows() {
      let reviews = []
      this.operations.forEach(phase => {
        reviews = [...reviews, ...phase.programs_assignmenttables.map(p => ({
          ...p,
          operation_title: phase.title,
          operation_type: phase.operations_type,
        }))]
        phase.programs_operationstables.forEach(subphase => {
          reviews = [...reviews, ...subphase.programs_assignmenttables.map(sp => ({
            ...sp,
            operation_title: subphase.title,
            operation_type: subphase.operations_type,
          }))]
          subphase.programs_operationstables.forEach(activity => {
            reviews = [...reviews, ...activity.programs_assignmenttables.map(a => ({
              ...a,
              operation_title: activity.title,
              operation_type: 'Activity',
            }))]
          })
        })
      })
      return reviews
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        enabled: 'light-primary',
        disabled: 'light-danger',
        draft: 'light-secondary',
        null: 'light-secondary',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  methods: {
    copyToClipboard(text) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
          this.$bvToast.toast('Application link copied to clipboard', {
            title: 'Success',
            variant: 'success',
            solid: true,
            autoHideDelay: 3000,
          })
        }).catch(() => {
        })
      } else {
        const textarea = document.createElement('textarea')
        textarea.value = text
        textarea.style.position = 'fixed' // ensure the textarea is rendered offscreen
        document.body.appendChild(textarea)
        textarea.focus()
        textarea.select()
        try {
          document.execCommand('copy') // execute the copy command
        } catch (err) {
          // failed to copy
        } finally {
          document.body.removeChild(textarea) // remove the textarea from the DOM
        }
      }
    },
    deleteModule() {
      this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete this module?',
        {
          okTitle: 'No, Thanks',
          cancelTitle: 'Yes, Delete',
          title: 'Delete File',
        },
      )
    },
    advanceSelector(index) {
      for (let i = 0; i < this.feedbacks.length; i += 1) {
        this.feedbacks[i].open = false
        this.feedbacks[i].selected = false
      }
      this.feedbacks[index].open = true
      this.feedbacks[index].selected = true
    },
    openFeedbackModal() {
      for (let i = 0; i < this.feedbacks.length; i += 1) {
        this.feedbacks[i].open = false
        this.feedbacks[i].selected = false
      }
      this.feedbacks[0].open = true
      this.feedbacks[0].selected = true
      this.$bvModal.show('feedbackModal')
    },
    handleFeedbackModalOkClick() {
      this.$bvModal.hide('feedbackModal')
      this.$bvModal.show('feedbackForwardModal')
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    addReview() {
      this.mutationLoading = true
      this.$apollo.mutate(
        {
          mutation: gql`mutation($data: [programs_assignmentquestionstable_insert_input!]!) {
                        insert_programs_assignmenttable_one(object: {title: "${this.reviewTitle}",type: "${this.reviewType}", status: "draft", review_status: "draft", operations_id: ${this.selectedOperation}, programs_assignmentquestionstables: {data: $data}}) {
                          id
                        }
                      }`,
          variables: {
            data: this.applicationForm,
          },
          update: (store, { data: { insert_programs_assignmenttable_one } }) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: insert_programs_assignmenttable_one.id ? 'Review added successfully' : 'Failed to create review',
                icon: insert_programs_assignmenttable_one.id ? 'CheckIcon' : 'XIcon',
                variant: insert_programs_assignmenttable_one.id ? 'success' : 'danger',
              },
            })
            this.$apollo.queries.operations.refetch()
            this.mutationLoading = false
          },
        },
      )
      this.reviewTitle = null
      this.selectedOperation = null
      this.applicationForm = null
      this.reviewType = null
    },
    updateAssignmentStatus(status, item, col) {
      const update = status === 'enabled' ? 'disabled' : 'enabled'
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_programs_assignmenttable_by_pk(pk_columns: {id: ${item}}, _set: {${col}: ${update}}) {
            id
          }
        }`,
        update: () => {
          this.$apollo.queries.operations.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    operations: {
      query() {
        return gql`
                {
                  programs_operationstable(order_by: {id: desc}, where: {program_id: {_eq: ${this.$route.params.id}}, operations_type: {_eq: "Phase"}}) {
                    id
                    title
                    operations_type
                    programs_assignmenttables(order_by: {id: desc}) {
                      #Phase
                      id
                      title
                      type
                      status
                      review_status
                    }
                    programs_operationstables(order_by: {id: desc}, where: {operations_type: {_eq: "SubPhase"}}) {
                      id
                      title
                      operations_type
                      programs_assignmenttables(order_by: {id: desc}) {
                        #Subphase
                        id
                        title
                        type
                        status
                        review_status
                      }
                      programs_operationstables(order_by: {id: desc}) {
                        id
                        title
                        operations_type
                        programs_assignmenttables(order_by: {id: desc}) {
                          #Activities
                          id
                          title
                          type
                          status
                          review_status
                        }
                      }
                    }
                  }
                }`
      },
      update: data => data.programs_operationstable,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
